.comments{
    background: url('../../assets/images/street.png');
    width: 100%;
    height: 505px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.comments-image {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    height: 100%;
    width: 100%;
}

.comments-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    height: 100%;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
    box-sizing: border-box;
}

.comments-main .title {
    font-size: 48px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    max-width: 1100px;
    margin: 0 auto
}

.comments-main .comment{
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    margin: unset;
}

.comments-main .author {
    font-size: 36px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    margin: 30px auto 0 auto;
}

@media only screen and (max-width: 700px) {
    .comments{
        display: block;
    }

    .comments-image {
        height: 96%;
    }

    .comments-main{
        padding-right: 20px;
    }
    
    .comments-main .title {
        font-size: 36px;
    }
    
    .comments-main .comment{
        font-size: 24px;
    }

    .comments-main .author {
        font-size: 32px;
    }
}