.scalability {
    background: url('../../assets/images/unlimited.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 830px;
    height: 100%;
}

.scalability-main {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    min-height: 830px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    padding-left: 15%;
    padding-right: 15%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    font-weight: 700;
    font-size: 48px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    line-height: 40px;
}

.scalability-main hr {
    width: 40px;
    text-align: left;
    margin-left: 150px;
}

.scalability-main h2 {
    margin: 0;
    text-align: left;
    font-weight: 700;
    font-size: 48px;
    line-height: 68px;
}

.scalability-main p {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    margin: 0;
    max-width: 574px;
    text-align: left;
    padding-bottom: 100px;
}


@media only screen and (max-width: 768px) {
    
    .scalability,
    .scalability-main {
        min-height: 0;
    }

    .scalability-main h2 {
        margin-top: 20px;
        font-size: 32px;
        line-height: 40px;
    }

    .scalability-main hr {
        margin-left: 0;
    }

    .scalability-main p {
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 100px;
    }



}