.footer {
    background-color: #84004C;
    display: grid;
    grid-template-columns: 1fr 3fr 4fr;
    position: relative;
    z-index: 1;
    height: 700px;
}

.footer-romb{
    text-align: start;
}

.footer-romb img{
    width: 207px;
    height: 310px;
    left: 1px;
}

.footer-info {
    font-style: normal;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 20px;
    height: auto;
    /*height: 500px;*/
    margin-top: -20px;
    max-width: 500px;
}

.footer-info .title {
    font-weight: 700;
    font-size: 48px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    line-height: 58px;
    text-align: start;
    margin-bottom: 0;
    margin-top: 0;
}

.footer-info .description {
    text-align: start;
    font-weight: 400;
    font-size: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 40px;
}

.footer-options{
    text-align: start;
    height: 100%;
    display: inline-block;
    display: flex;
    align-items: center;
    max-width: 400px;
}

.footer-options__list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    height: 500px;;
    padding: 0 10px;
}

.footer-options li {
    list-style: none;
    font-weight: 400;
    font-size: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 40px;
    color: #FFFFFF;
    padding-top: -10px;
}

.footer-options img{
    width: 30px;
    margin-right: 10px;
}

.footer-chess{
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 374px;
    max-height: 493px;
    object-fit: cover;
    z-index: -1;
}

@media only screen and (max-width: 1200px) {
    .footer {
        grid-template-columns: 1fr 6fr;
        height: auto;
    }

    .footer-info {
        padding: 50px;
        max-width: 650px;
    }

    .footer-options{
        grid-column: 2/3;
        max-width: 100%;
    }

    .footer-options__list{
        height: auto;
        padding: 20px 20px 150px 50px;
        margin-bottom: 200px;
    }
}

@media only screen and (max-width: 700px) {
    .footer {
        display: block;
    }

    .footer-romb{
        display: none;
    }

    .footer-options__list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        height: 100%;
        padding: 0px 50px;
        margin-top: 0;
        margin-bottom: 450px;
    }

    .footer-info .title {
        font-size: 32px;
    }

    .footer-info .description,
    .footer-options li {
        font-size: 24px;
    }
}