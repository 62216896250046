.home-main{
    margin: 0;
    background-color: #f2f3f3;
    height: 100%;
    padding-top: 1px;
    padding-bottom: 1px;
}

.home_title {
    font-family: 'Montserrat';
    font-weight: 900;
    font-weight: bolder;
}
