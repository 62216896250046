.formSection{
    margin-top: 200px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    margin-bottom: 150px;
}

.formSection-flex{
    padding-top: 0;
    display: flex;
    justify-content: center;
    max-width: 1206px;
    margin: auto;
    gap: 50px;
}

.formSection-box {
    width: 33.33%;
}

.formSection-box p{
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(211, 209, 209, 0.5);
    font-size: 24px;
    text-align: left;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 40px;
    color: #555555
}

.formSection-box h1{
    font-size: 60px;
    text-align: left;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    
}

@media only screen and (max-width: 1000px) {
    .formSection{
        margin-top: 150px;
    }

    .formSection-flex{
        display: block;
        max-width:none
    }

    .formSection-box{
        margin-left: 2%;
        width: 100%;
    }

    .formSection-box h1{
        width: 100%;
    }
}