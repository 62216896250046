.collaborations {
    padding: 50px 50px;
    color: #555555;
}

.collaborations-info .title {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    line-height: 58px;
    text-align: center;
    padding: 0 30px;
}

.collaborations-info .description {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 40px;
    max-width: 1099px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 10px;
    text-align: center;
}

.collaborations-collabs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 80px 20px;
    gap: 40px;
}

.collabs-info{
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 5;
}

.collabs-info>h2{
    color: #84004c;
    font-family: 'TradeGothic LT CondEighteen',sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    margin: 0;
}

.collabs-info>h3{
    font-family: 'TradeGothic LT CondEighteen',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    margin:0
}

.descriptionFont{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 40px;
    margin: 0;
}

.collabs-images{
    display: flex;
    flex: 8;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1235px) {
    .collaborations-collabs {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .collabs-images{
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .collaborations {
        padding: 20px 20px;
    }

    .collaborations-info .title {
        padding: 0;
        font-size: 32px;
        line-height: 38px;
    }

    .collaborations-info .description {
        padding: 0;
        font-size: 24px;
        line-height: 36px;
    }

    .collabs-info>h3, 
    .collabs-info>h2{
        font-size: 32px;
        line-height: 38px;
    }

    .descriptionFont{
        font-size: 20px;
        line-height: 36px;
    }
}