.formInput-wrapper{
    font-size: 18px;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 40px;
    color: #555555
}

.formInput input{
    height: 43px;
    width: 100%;
    border: 1px solid rgba(211, 209, 209, 0.5);
    color: #555555;
    font-family: Roboto Condensed;
}

.formInput textarea{
    height: auto;
    width: 100%;
    border: 1px solid rgba(211, 209, 209, 0.5);
    transition: border 5s;
    font-family: Roboto Condensed;
}

.formInput input:hover,
.formInput textarea:hover{
    border: 1px solid rgba(224, 222, 222, 0.5);
    transition: border 0.5s;
}

.formInput input:focus,
.formInput textarea:focus{
    outline: none;
}