.whatDoes {
    color: white;
    background-color: #84004C;
    display: grid;
    grid-template-columns: 1fr 7fr 2fr;
    height: 856px;
}

.whatDoes-romb {
    display: inline-block;
}

.whatDoes-romb img {
    width: 207px;
    height: 310px;
}

.WhatDoes-main {
    text-align: center;
}

.whatDoes-info {
    z-index: 3;
    position: relative;
    display: inline-block;
    max-width: 667px;
    margin: 30px 50px 30px -50px;
    text-align: center;
}

.whatDoes-info .title {
    font-weight: 700;
    max-width: 667px;
    width: 100%;
    font-size: 48px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    line-height: 57.6px;
    display: block;
    margin-right: 0;
    text-align: start;
}

.whatDoes-info .description {
    max-width: 667px;
    font-weight: 400;
    font-size: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 40px;
    display: block;
    text-align: start;
}

.whatDoes-bottle {
    position: absolute;
    width: 100%;
    right: 0;
    z-index: 2;
    text-align: right;
    margin-top: -290px;
}

.whatDoes-bottle img {
    max-width: 798px;
    max-height: 538px;
}


@media only screen and (max-width: 1200px) {

    .whatDoes {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0
    }

    .whatDoes-romb img {
        position: absolute;
        left: 0;
    }

    .whatDoes-info {
        display: block;
        margin: auto;
        padding: 10%;
    }

    .whatDoes-bottle {
        display: block;
        /*background-color: blue;*/
        position: relative;
        text-align: center;
        margin: 0;
    }

    .whatDoes-bottle img {
        /*background-color: aqua;*/
        margin: auto;
        display: block;
        width: 100%;
    }
}


@media only screen and (max-width: 700px) {
    .whatDoes-romb {
        display: none;
    }

    .whatDoes-info .title {
        font-size: 32px;
        line-height: 38px;
        margin-top: 50px;
        margin-bottom: 100px;
        text-align: center;
    }

    .whatDoes-info .description {
        font-size: 24px;
        line-height: 36px;
    }
}