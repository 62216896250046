.functionBox {
    width: 100%;
    height: 100%;
}

.functionBox-main {
    min-width: 320px;
    max-width: 384px;
    height: 300px;
    padding: 30px;
    border: 1px solid #FFFFFF;
    border-radius: 22px;
    margin: auto;
}

.functionBox-icon {
    width: 82px;
    height: 82px;
    border: none;
    border-radius: 50%;
    background-color: #6C013F;
    padding: 0px;
}

.dollar .functionBox-icon {
    padding: 10px;
    width: 60px;
    height: 60px;
}

.functionBox-icon img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    margin-top: 8px;
}



.functionBox-info p {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 40px;
    margin: 8px 15px 30px 15px;
}


@media only screen and (max-width: 768px) {

    .functionBox-info p {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 0;
        padding-left: 0px;
    }

    .functionBox-main {
        min-width: 0;
        min-height: 300px;
        height: 100%;
        padding: 10px 20px;
    }
}