.navBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: rgb(255, 255, 255, 0.2);
    z-index: 5;
    height: 100px;
    transition: background 0.3s, color 0.3s
}

.navBar img {
    width: 216px;
}

.navBar__logo {
    position: relative;
    margin-right: auto;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navBar__options {
    position: relative;
    display: flex;
    gap: 50px;
    justify-content: space-around;
    align-items: center;
    margin-right: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    transition: left 0.5s, background 0.3s, color 0.3s;
}

.navBar__option {
    margin-right: 50px;
    text-decoration: none;
    list-style: none;
    color: white;
    cursor: pointer;
}

.navBar__option.black-font {
    color: #000000;
    margin-right: 50px;
}

.navBar .container {
    display: none;
    cursor: pointer;
    margin-top: 15px;
    margin-right: 25px;
}

.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 5px;
    background-color: #84004C;
    margin: 6px 0;
    transition: 0.4s;
}


.change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
}

.navBar__option:hover {
    color: rgba(209, 159, 195, 0.95);
    
}

@media only screen and (max-width: 1200px) {

    .navBar{
        height: 75.97px;
    }

    .change .navBar__options {        
        transition: left 0.5s, background 0.3s, color 0.3s;
    }

    .navBar__options {
        transition: left 0.5s, background 0.3s, color 0.3s;
    }
}


@media only screen and (max-width: 768px) {
    .navBar {
        height: 65px;
    }

    .navBar img {
        width: 132px;
    }

    .navBar__logo {
        margin-left: 15px;
    }

    .navBar .container {
        display: inline-block;
    }

    .change .navBar__options {        
        transition: left 0.5s, background 0.3s, color 0.3s;
        left: 20%;
    }

    .navBar__options {
        margin-right: 0px;
        display: flex;
        width: 80%;
        gap: 0;
        position: absolute;
        margin-top: 65px;
        flex-direction: column;
        left: 1500px;
        background: #FFFFFF;
        transition: left 0.5s, background 0.3s, color 0.3s;
    }
    .navBar__option.black-font,
    .navBar__option{
        margin: 0;
        flex-basis: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .navBar__option.black-font {
        color: #ffffff;
    }

    .navBar__option:hover{
        background-color: rgba(190, 113, 167, 0.75);
        
    }
}


@media only screen and (max-height: 600px)  and (max-width: 768px){
    .navBar__option.black-font,
    .navBar__option{
        height: 100%;
        padding: 0px;
        width: 100%;
        margin-right: 0px;
    }

    .navBar__option:hover {
        color: rgba(82, 18, 63, 0.95);
        
    }
}