.whatIs {
    display: flex;
    margin: 0px 10px 20px 10px;
    padding: 30px 0;
    gap: 50px;
    color: #555555;
}

.whatIs-image {
    flex: 1;
    margin: auto;
    width: 55%;
}

.whatIs-image__img {
    display: block;
    text-align: center;
}

.whatIs-image__img img{
    max-width: 450px;
}

.whatIs-info {
    padding: 0px;
    flex:1;
    
}

.whatIs-info__main .title {
    font-weight: 700;
    font-size: 38px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    line-height: 57.6px;
    text-align: start;
    margin-bottom: 10px;
}

.whatIs-info__main .description {
    font-weight: 400;
    font-size: 21px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 40px;
    width: 565px;
    text-align: start;
    margin-top: 5px;
}

.whatIs-info hr {
    margin: 30px 30px;
    background: linear-gradient(to right, transparent 0%, #6e6d6d 50%, transparent 100%);
    height: 1.5px;
    border: none;
    max-width: 600px;
}

.whatIs-info__platform .title {
    font-weight: 700;
    font-size: 30px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    line-height: 48px;
    text-align: start;
    margin-bottom: 0px;
}

.whatIs-info__platform .listBox {
    display: flex;
    text-align: left;
    padding: 0;
}

.whatIs-info__platform .left {
    flex-basis: 350px;
    padding-left: 0px;
}

.whatIs-info__platform .right {
    padding-left: 0px;
    flex-basis: 350px;
}

.whatIs-info__platform .component {
    list-style-position: outside;
    list-style: none;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 54px;
    display: flex;
    align-content: flex-end;
    justify-content: baseline;
    height: 45px;
}

.whatIs-info__platform .component-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    height: 50px;
}

.whatIs-info__platform .component-logo img{
    width: 30px;
    object-fit: contain;
}

.whatIs-info__platform .component-content{
    display: inline;
    padding-left: 2px;
}

@media only screen and (max-width: 1200px) {

    /* For mobile phones: */
    .whatIs {
        flex-direction: column;
        gap: 50px;
    }

    .whatIs-info {
        align-self: center;
    }

    .whatIs-image img {
        width: 100%;
        max-width: 508px;
        height: auto;
    }
}


@media only screen and (max-width: 768px) {

   /* For mobile phones: */ 
    .whatIs-info {
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
    }
    
    .whatIs-info__main{
        margin: 0;
        padding: 0;
        display: block;
    }

    .whatIs-info__main .title,
    .whatIs-info__platform .title {
        font-size: 32px;
        line-height: 38px;
    }

    .whatIs-info__main .description {
        font-size: 24px;
        line-height: 36px;
        width: 100%;
    }

    .whatIs-info__platform .component {
        font-size: 24px;
    }

    .whatIs-info__platform .listBox {
        display: block;
    }

    .whatIs-info__platform .left {
        margin-bottom: 0;
    }
    .whatIs-info__platform .right {
        margin-top: 0;
    }

    .whatIs-info__platform .component-logo{
        height: 52px;
    }

    .whatIs-info__platform .component-logo img{
        width: 20px;
        object-fit: contain;
    }

    .whatIs-info__main .title{
        display: block;
        width: unset;
        margin: auto;
        padding: unset;
        box-sizing: border-box;
    }
}