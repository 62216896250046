.mapBox {
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    width: 100%;
    margin-top: 200px;
    margin-bottom: 100px;
}

.mapBox-main {
    background: url('../../assets/images/map.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 90%;
    min-height: 666px;
    margin: auto;
    border-radius: 20px;
}

.mapBox-main__info {
    text-align: center;
    padding: 100px 20% 20px 20%;
}

.mapBox-main__info hr {
    width: 50px;
    background-color: #ffffff;
    border: solid 1px white;
}

.mapBox-main__info .title {
    font-style: normal;
    margin-bottom: 0px;
    font-weight: 0;
    line-height: 40px;
    font-family: 'Libre Baskerville', sans-serif;
}

.mapBox-main__info .description {
    margin-top: 0px;
    line-height: 68px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
}

@media only screen and (max-width: 1200px) {
    .mapBox {
        margin-top: 120px;
    }

    .mapBox-main {
        min-height: 0;
    }

    .mapBox-main__info {
        padding: 100px 10% 20px 10%;
    }
}

@media only screen and (max-width: 768px) {
    .mapBox {
        font-size: 24px;
        margin-bottom: 50px;
    }

    .mapBox-main__info .description {
        line-height: 36px;
    }

    .mapBox-main__info {
        padding: 10%;
    }
}