.hero {
    display: block;
    margin: 0;
}

.heroImage {
    width: 100%;
    position: relative;
    z-index: -1;
}

.hero__image{
    position: relative;
}

.hero__presentation {
    position: absolute;
    top: 0;
    z-index: -1;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
}

.hero__box {
    position: relative;
    margin-left: 40px;
    padding: 40px;
    text-align: left;
    max-width: 1050px;
    /*width: 664px;*/
}

.hero__title h1 {
    color: white;
    text-align: left;
    font-weight: 400;
    font-size: 50px;
    line-height: 79.36px;
    font-family: 'Libre Baskerville', sans-serif;
}

@media only screen and (max-width: 1200px) {
    .hero__title h1 {
        font-size: 38px;
        line-height: 59.52px;
    }

    .hero__box {
        margin-left: 20px;
        max-width: 750px;
    }

    .heroImage {
        min-height: 530px;
    }
    .heroImage {
        object-fit: cover;
    }
}

@media only screen and (max-width: 768px) {

    .hero__title h1 {
        font-size: 26px;
        line-height: 45px;
    }

    .heroImage {
        width: 100%;
        height: 530px;
    }

    .hero__presentation {
        height: 530px;
        justify-content: flex-end;
    }

    .hero__box {
        margin-top: 0px;
        margin-left: 0px;
        padding-left: 10px;
        padding-right: 0px;
        width: 90%;
    }
}