.functions {
    background: #84004C;
    color: white;
    padding: 30px 0px 80px 0px;
    z-index: -2;
    width: 100%;
}

.romb img {
    width: 161px;
    height: 241.11px;
    position: absolute;
    left: 0;
    margin-top: -30px;
    z-index: 0;
}

.functions-main {
    position: relative;
}

.functions-main .title h2 {
    margin: 50px 0px 120px 0px;
    text-align: left;
    padding-left: 150px;
    font-weight: 700;
    font-size: 48px;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    line-height: 40px;
}

.functions-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    row-gap: 50px;
    column-gap: 10px;
    padding: 0 20px 0 20px;
}



@media only screen and (max-width: 1200px) {

    /* For mobile phones: */
    .functions-grid {
        grid-template-columns: 1fr 1fr;
        padding: 0 20px 0 20px;
    }

    .functions-main .title h2 {
        text-align: center;
        padding-left: 0;
        font-size: 40px;
        line-height: 40px;
    }
}


@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .functions-grid {
        grid-template-columns: 1fr;
    }

    .functions-main .title h2 {
        font-size: 32px;
        line-height: 40px;
        text-align: left;
        padding-left: 10px;
        margin-top: 30px;
        margin-bottom: 60px;
    }

    .romb img {
        display: none;
    }
}