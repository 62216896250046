.form{
    width: 66.66%;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 8%;
    padding-bottom: 8%;
    background-color: white;
    border-radius: 20px;
}

.formSection-title h3{
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
}

.form hr{
    height: 1px;
    border: none;
    background-color: rgba(211, 209, 209, 0.5);
}

.formSection-boxContent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    font-family: Roboto Condensed;
}

.contact-input {
    font-family: Roboto Condensed;
}

.formSection-title{
    text-align: left;
    font-size: 26px;
    color: #a81466;
}

.boxContent-thirdSection{
    grid-column: 1/3;
}

.formSection-boxContent button{
    margin-top: 40px;
    width: 150px;
    color: white;
    
    border: none;
    cursor: pointer;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1000px) {
    .formSection-boxContent{
        display: block;
        text-align: left;
    }

    .form{
        width: 100%;
        margin-top: 65px;
        padding-top: 10px;
    }
}


.fusion-form-input > .PhoneInputInput {
    height: 43px;
    width: 100%;
    border: 1px solid rgba(211, 209, 209, 0.5);
    color: #555555;
    font-family: Roboto Condensed;
}