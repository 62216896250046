.collabsGroup{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
    flex-basis: 500px;
    gap: 20px;
    max-width: 800px;
}

@media only screen and (max-width: 800px) {
    .collabsGroup{
        flex-wrap: nowrap;
        width: 100%;
        max-width: 100%;
        overflow: auto;
        justify-content: flex-start;
    }
}