.readyFooter{
    background: #69053f;
    display: flex;
    flex-direction: column;
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
}

.readyFooter__up{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.readyMain{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.readyMain .title{
    color: #FFFFFF;
    font-size: 70px;
    margin: 0px;
}

.readyMain .getTouch{
    padding: 15px 40px;
    font-size: 15px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    background: #971978;
    color: #FFFFFF;
    transition: all 0.1s linear;
    text-shadow: #69053f;
}

.readyMain .getTouch:hover{
    background: #971977c0;
}

.readyFooter__down{
    display: flex;
    justify-content: center;
    border-top: 1px solid #ffffff1a;
}

.readyDown__container{
    flex-basis: 1200px;
    display: flex;
    flex-direction: column;
    height: 225px;
    padding: 30px 50px;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 40px;
}

.ready__logo img{
    max-width: 190px;
    cursor: pointer;
}

.ready__end{
    display: flex;
    width: 100%;
}

.readyEnd__container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ready__copyright{
    font-family:  'Roboto Condensed', sans-serif;
    color: #FFFFFF;
    font-size: 14px;
}

.ready__social{
    display: flex;
    align-items: center;
    gap: 20px;
}

.ready__social img{
    width: 18px;
}

@media only screen and (max-width: 768px) {

    .readyFooter__up{
        padding: 0px 20px;
    }
    
    .readyMain .title{
        font-size: 52px;
    }

    .readyMain .getTouch{
        padding: 12px 20px;
        font-size: 14px;
    }
    
    .ready__mainLogo{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }

    .ready__logo{
        display: flex;
        flex-basis: 300px;
    }

    .readyDown__container{
        padding: 0px;
        gap:0px;
        justify-content: space-evenly;
    }

    .ready__end{
        border-top: 1px solid #FFFFFF;
        width: 100%;
        margin: 0;
        padding: 20px 20px 20px 30px;
    }

    .readyEnd__container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}