.collabImageMain{
    max-width: 299px;
    max-height: 304px;
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid #84004C;
    border-radius: 22px;
    margin: 20px 0;
    flex-basis: 400px;
}



.collabImageMain-image{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    flex: 1;
}

.collabImageMain img{
    object-fit: cover;
    height: 222px;
    width: 215px;
    object-fit: cover;
    padding: 20px;
}



.collabImageMain-title{
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #810F50;
    border-radius: 0px 0px 20px 20px;
}

.collabImageMain-title>p{
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    
    color: #FFFFFF;
}
